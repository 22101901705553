import React from 'react'
import { PageProps } from 'gatsby'
import ServiceBanner from '../../components/ServiceBanner'
import AdditionalTiles from '../../components/AdditionalTiles'
import accessoiresTiles from '../../data/accessoiresTiles'
import ServiceContent from '../../components/ServiceContent'
import Button from '../../components/Button'
import { servicesBreadcrumbElement } from '.'
import { LayoutProps } from '../../components/Layout.context'
import { Helmet } from 'react-helmet'
import { magasinLink } from '../../data/menu'

const title = 'Accessoires'

const AccessoiresPage: React.FC<PageProps> = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Découvrez tous nos accessoires pour smartphones : coque, film et vitre de protection, chargeurs,  câbles datas et batteries rechargeables."
        />
      </Helmet>

      <ServiceBanner
        title={title}
        background="/images/services/2_accessoires/Accessoires-mobiles-banner.jpg"
        parallax
      />
      <ServiceContent>
        <ServiceContent.Paragraph>
          Eh ! Vous savez quoi ? Nos magasins Save ne réparent pas uniquement
          vos smartphones, ils mettent aussi à votre disposition toute une
          panoplie d'accessoires pour votre fidèle compagnon et vous faciliter
          son usage.
        </ServiceContent.Paragraph>
        <Button.ExternalLink href={magasinLink}>
          Où en trouver ?
        </Button.ExternalLink>
        <ServiceContent.Title>
          Protections pour votre smartphone
        </ServiceContent.Title>
        <ServiceContent.Paragraph>
          Vous voulez éviter de venir nous voir trop souvent, nous vous
          conseillons de bien protéger votre smartphone. Pour cela, vous pouvez
          retrouver dans nos magasins Save des accessoires permettant de
          protéger votre smartphone.
        </ServiceContent.Paragraph>
        <ServiceContent.Paragraph>
          Des coques pour le sublimer et le protéger contre les aléas du
          quotidien. Des écrans de protection pour une vision comme au premier
          jour. Des vitres en verre trempé pour absorber les chocs des
          smartphones les plus cascadeurs
        </ServiceContent.Paragraph>
        <ServiceContent.Title>Accesoires de charge</ServiceContent.Title>
        <ServiceContent.Paragraph>
          A court de batterie ? Trop peu pour vous ! Parce qu'on sait à quel
          point c'est important pour vous de toujours rester connecté (vos
          abonnés nous remercieront!), avec Save votre téléphone ne pourra plus
          vous faire le coup de la panne. Quel que soit votre smartphone et
          votre besoin, vous aurez toujours un magasin Save pour vous équiper en
          chargeur et câble de chargement pour rester connecté, tout au long de
          la journée !
        </ServiceContent.Paragraph>
        <ServiceContent.Title>Charge sans fil (induction)</ServiceContent.Title>
        <ServiceContent.Paragraph>
          Brancher, débrancher encore et toujours… Stop ! Avec la gamme de
          chargeur à induction Save, posez et ça charge ! (si votre smartphone
          dispose de la technologie, bien sûr !)
        </ServiceContent.Paragraph>
        <ServiceContent.Title>Batterie externe</ServiceContent.Title>
        <ServiceContent.Paragraph>
          Manquer de batterie pendant un voyage ou un trajet un peu long ? Pas
          possible avec notre gamme de batteries de secours Save! Que votre
          appareil soit un vrai gourmand ou pas, vous trouverez la batterie
          adaptée à votre besoin grâce à notre gamme allant de 2600mAh à 8000
          mAh.
        </ServiceContent.Paragraph>
        <AdditionalTiles more items={accessoiresTiles} />
      </ServiceContent>
    </>
  )
}

const layoutProps: LayoutProps = {
  title: `Retrouvez dans nos points de vente une large gamme d'accessoires pour votre smartphone`,
  breadcrumb: [servicesBreadcrumbElement, { label: title }],
  showHighlights: true,
}

export default Object.assign(AccessoiresPage, {
  layoutProps,
})
